export function handleResponse(response: any) {
  if (response?.data?.listDimDocks30s?.items?.length > 0) {
    return response.data.listDimDocks30s.items;
  }
  return null;
}

export function handleError(error: any) {
  if (error.data) {
    return error.data;
  }

  return error;
}
