import { FloatingMenu } from "../floating_menu/FloatingMenu";

export const PageNotFound = () => {
    return (
      <>
        <div>
          <h2>404</h2>
          <h3>Oops, nothing here...</h3>
          <p>Please Check the URL and Enter Correct Project Name</p>
        </div>
        <FloatingMenu />
      </>
    );
  };
  