import { API, graphqlOperation } from "aws-amplify";
import { handleResponse } from "../config/apiMiddleWare";
import { listDimDocks30s } from "../../graphql/queries";

export const UserAPI = {
  async getAssignedUserData(
    organizationId: string,
    projectId: string,
    serviceId: string,
    userId: string
  ) {
    const queryParams = {
      limit: 5000000,
      filter: {
        pk: {
          eq: `ORGANIZATION#${organizationId}`,
        },
        sk: {
          eq: `PROJECT#${projectId}SERVICE#${serviceId}USER#${userId}`,
        },
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.graphql(
          graphqlOperation(listDimDocks30s, queryParams)
        );
        resolve(handleResponse(result));
      } catch (error) {
        reject(error);
      }
    });
  },
};
