import { API, graphqlOperation } from "aws-amplify";
import { listDimDocks30s } from "../../graphql/queries";
import { handleResponse } from "../config/apiMiddleWare";

export const OrganizationAPI = {
  async getOrganizationByOrganizationName(
    organizationName: string
  ): Promise<any> {
    const queryParams = {
      limit: 5000000,
      filter: {
        pk: {
          eq: `ORGANIZATION`,
        },
        sk: {
          contains: `ORGANIZATION`,
        },
        OrganizationName: {
          eq: organizationName,
        },
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.graphql(
          graphqlOperation(listDimDocks30s, queryParams)
        );
        resolve(handleResponse(result));
      } catch (error) {
        reject(error);
      }
    });
  },
};
